import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { useLayout } from '~hooks/useLayout';
import ServerContext from '~src/serverContext';
import { useSeo } from '~hooks/useSeo';
import { UserContext } from '~contexts/user';
import { renderFirstName } from '~helpers/common';

import NotFound from '~components/not-found';

import Breadcrumbs from './breadcrumbs';
import CustomerProfile from './profile';

const CustomerPage = () => {
  useLayout({});

  const intl = useIntl();
  const location = useLocation();
  const cache = useContext(ServerContext);
  const cacheData = cache.get('serverCustomerProfile');

  const [isLoading, setIsLoading] = useState(!cacheData);
  const [data, setData] = useState(cacheData || null);
  const [cacheProfileNotFound, setCacheProfileNotFound] = useState(cache.get('serverCustomerNotFound'));
  const { personId } = useParams();
  const { getCustomer } = useContext(UserContext);
  const fName = renderFirstName(data?.firstName);

  useSeo(
    data
      ? intl.formatMessage(
          {
            id: 'containers.profile.seo.customer.title',
          },
          {
            name: data.firstName,
            city: data?.location?.city,
          }
        )
      : null
  );

  useEffect(async () => {
    if (cacheProfileNotFound) {
      setIsLoading(false);
      return;
    }

    if (cacheData) {
      cache.del('serverCustomerProfile');
      cache.del('serverCustomerNotFound');
      return;
    }

    setCacheProfileNotFound(false);
    setIsLoading(true);

    try {
      const fetchedCustomer = await getCustomer(personId);
      setData(fetchedCustomer);
      setIsLoading(false);
    } catch (err) {
      setData(null);
      setCacheProfileNotFound(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return !isLoading && cacheProfileNotFound ? (
    <NotFound
      headline={intl.formatMessage({
        id: 'containers.profile.customer.not-found.headline',
        defaultMessage: '404 - Not Found',
      })}
      content={intl.formatMessage({
        id: 'containers.profile.customer.not-found.content',
        defaultMessage: 'Customer not found',
      })}
    />
  ) : (
    <>
      <Breadcrumbs isLoading={isLoading} firstName={fName} />
      <section className="c-profile l-container" itemScope itemType="http://schema.org/Product">
        <meta itemProp="brand" content="Juan Pescador" />
        <meta itemProp="sku" content={personId} />
        <meta itemProp="name" content={fName} />
        {data && data.profilePicture && data.profilePicture.large ? (
          <link itemProp="image" href={data.profilePicture.large} />
        ) : null}
        <meta
          itemProp="description"
          content={
            data?.personalNote ||
            intl.formatMessage(
              {
                id: 'containers.profile.seo.worker.description.default',
              },
              {
                name: fName,
                city: data?.location?.city || data?.location?.country,
              }
            )
          }
        />
        <div itemProp="offers" itemType="http://schema.org/Offer" itemScope>
          <link itemProp="url" href={location.pathname} />
          <meta itemProp="availability" content="https://schema.org/InStock" />
          <meta itemProp="price" content={data && data.price ? data.price.amount * 0.01 : null} />
          <meta itemProp="priceCurrency" content={data && data.price ? data.price.currency : null} />
          <meta itemProp="priceValidUntil" content={moment().add(1, 'year').endOf('year').format('YYYY-MM-DD')} />
          {data && data.score && data.score.votes && data.score.votes > 0 ? (
            <div itemProp="aggregateRating" itemType="https://schema.org/AggregateRating" itemScope>
              <meta itemProp="reviewCount" content={data.score.votes} />
              <meta itemProp="ratingValue" content={data.score.score} />
            </div>
          ) : null}
        </div>
        <CustomerProfile isLoading={isLoading} {...data} />
      </section>
    </>
  );
};

export default CustomerPage;
