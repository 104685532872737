import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useListingRedirect } from '~hooks/useListingRedirect';
import { SkeletonPrimary } from '~components/skeleton';
import Breadcrumb from '~components/breadcrumbs';

const Breadcrumbs = ({ isLoading, firstName }) => {
  const intl = useIntl();
  const urlLocation = useLocation();
  const listingRedirect = useListingRedirect();

  return (
    <aside className="c-subheader">
      <div className="l-container">
        {!isLoading ? (
          <Breadcrumb.Wrapper className="c-breadcrumb--force-wrap">
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={listingRedirect} index={1}>
                {intl.formatMessage({ id: 'breadcrumbs.home', defaultMessage: 'Home' })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={urlLocation.pathname} index={3}>
                {firstName}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
          </Breadcrumb.Wrapper>
        ) : (
          <SkeletonPrimary>
            <Skeleton count={1} width={300} />
          </SkeletonPrimary>
        )}
      </div>
    </aside>
  );
};

export default Breadcrumbs;
